import { PoiTeaserFragmentFragment } from '../../../generated/types';
import { TeaserProps } from '../../../types/global-types';
import Card from '../../Common/Card';
import Location from '../../Common/Location';
import getAppConfig from '../../../utils/getAppConfig';

type PoiTeaserProps = TeaserProps & {
  data: PoiTeaserFragmentFragment;
};

const PoiTeaser: React.FC<PoiTeaserProps> = ({
  data,
  showCategory,
  ...rest
}) => {
  const appConfig = getAppConfig();
  const isInfolino = appConfig.config.frontendId === 'infolino';
  const url = isInfolino
    ? `https://www.freizeithotels.info${data.url}`
    : data.url;

  return (
    <Card
      key={data.id}
      href={url}
      image={data.teaserImage?.teaser?.url}
      title={data.title}
      text={data.body!}
      meta={
        <Location
          country={data.country?.name || ''}
          region={data.region?.name || ''}
        />
      }
      target={isInfolino ? '_blank' : '_self'}
      {...rest}
    />
  );
};

export default PoiTeaser;
