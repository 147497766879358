import { Heading, Box, Flex } from '@chakra-ui/react';
import React from 'react';

interface ContentTitleProps {
    title: String;
    small?: boolean;
    ActionComponent?: JSX.Element;
}

const ContentTitle: React.FC<ContentTitleProps> = ({
    title,
    small,
    ActionComponent,
}) => {
    return (
        <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h4" fontSize={small ? 'xl' : '2xl'}>
                {title}
            </Heading>
            {ActionComponent && <Box>{ActionComponent}</Box>}
        </Flex>
    );
};

export default ContentTitle;
