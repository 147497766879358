import React, { FC, useContext } from 'react';
import { Box } from '@chakra-ui/react';
import InfolinoContext from '../../../context/InfolinoContext';
import ContentTitle from '../../Common/ContentTitle';
import PoiTeaser from '../../Node/Poi/PoiTeaser';
import TeaserSlider from '../../Common/TeaserSlider';

type InfolinoPOIsProps = {};

const InfolinoPOIs: FC<InfolinoPOIsProps> = () => {
  const infolinoContext = useContext(InfolinoContext);

  return (
    <Box>
      <TeaserSlider
        TitleComponent={<ContentTitle title="Ausflugsziele & Sehenswertes" />}
        CardComponent={PoiTeaser}
        data={infolinoContext.hotel.poisFreizeithotels || []}
      />
    </Box>
  );
};

export default InfolinoPOIs;
